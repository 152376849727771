/** @format */

// /** @format */
// html {
//   max-width: 100%;
//   margin: 0;
//   padding: 0;
// }

// #canvas-container {
//   margin: 0 auto;
//   cursor: none;
//   width: 768px;
//   height: 720px;
//   position: relative;
//   background-image: url(../assets/images/bg-duckhunt.png);
//   background-color: #f7c1b8;
// }

// #canvas-container #round-counter {
//   pointer-events: none;
//   position: absolute;
//   bottom: 115px;
//   left: 65px;
//   background-color: black;
//   padding-left: 4px;
//   font-size: 70px;
//   line-height: 30px;
//   display: inline-block;
//   color: #9be3be;
// }
// #canvas-container #hit-counter {
//   pointer-events: none;
//   position: absolute;
//   bottom: 42px;
//   left: 195px;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
// }
// #canvas-container #hit-counter h2 {
//   font-size: 60px;
//   color: #9be3be;
// }
// #canvas-container #hit-counter ul {
//   padding-top: 2px;
//   list-style: none;
//   display: flex;
//   gap: 3px;
// }
// #canvas-container #hit-counter #hit-counter__enemies {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 30px;
// }
// #canvas-container #hit-counter #hit-counter__pass-limit {
//   color: white;
//   font-size: 25px;
//   transform: translateY(-5px);
// }
// #canvas-container #bullets-container {
//   pointer-events: none;
//   position: absolute;
//   bottom: 50px;
//   left: 76px;
// }
// #canvas-container #bullets-container #bullets {
//   list-style: none;
//   display: flex;
//   gap: 12px;
// }
// #canvas-container #bullets-container #bullets li img {
//   width: 12px;
//   height: 21px;
// }
// #canvas-container #bullets-container h2 {
//   color: #f7c1b8;
//   font-size: 38px;
//   line-height: 20px;
// }
// #canvas-container #score-counter {
//   pointer-events: none;
//   position: absolute;
//   bottom: 47px;
//   right: 45px;
//   color: white;
//   text-align: right;
// }
// #canvas-container #score-counter h2 {
//   font-size: 60px;
//   line-height: 28px;
// }

// #canvas-container #between-rounds-scoreboard {
//   font-size: 40px;
//   line-height: 28px;
//   text-align: center;
//   position: absolute;
//   padding: 20px;
//   background-color: black;
//   border-radius: 3px;
//   border: 3px solid white;
//   color: white;
//   width: 600px;
//   top: 50px;
//   margin-left: auto;
//   margin-right: auto;
//   left: 0;
//   right: 0;

//   .between-rounds-scoreboard__score-item {
//     padding-bottom: 10px;
//   }

//   .between-rounds-scoreboard__next-round {
//     animation: blink 1s infinite;
//     padding-top: 20px;
//     text-align: center;
//   }
// }

// #no-bullets-left {
//   font-size: 40px;
//   line-height: 28px;

//   position: absolute;
//   padding: 20px;
//   background-color: black;
//   border-radius: 3px;
//   border: 3px solid white;
//   color: white;
//   width: fit-content;
//   top: 250px;

//   margin-left: auto;
//   margin-right: auto;
//   left: 0;
//   right: 0;
// }

// @keyframes blink {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
//   100% {
//     opacity: 1;
//   }
// }

/** @format */
$primary-pink: #f7c1b8;
$primary-green: #9be3be;

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

body {
  background-color: #202020;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(../assets/images/hero-bg.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}

.App {
  display: flex;
  gap: 4rem;
  align-items: center;
}

.scoreboard {
  text-align: left;
  color: white;
  max-width: 375px;

  .logo {
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    h2 {
      &:last-child {
        text-align: right;
      }
    }

    .divider {
      height: 4px;
      background-color: white;
      width: 100%;
      margin: 0.5rem 0;
    }
  }
  // width: 100%;
  // padding: 0.5rem;
  // box-shadow: -15px 0 0 0 white, 15px 0 0 0 white, 0 -15px 0 0 white,
  //   0 15px 0 0 white;
  //  display: none;
}

.your-rank {
  padding-top: 1rem;
}

#scoreB {
  list-style: none;


  li {
    display: flex;
    justify-content: space-between;
    font-size: 1.75rem;
    padding-bottom: 0.2rem;

    .score-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

h3 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

button {
  padding: 1rem 2rem;
  background-color: $primary-green;
  border: 4px solid black;
  font-size: 2.5rem;
  margin: 0.5rem;
  cursor: pointer;
  display: inline-block;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 1px 3px 0 black;
  }
}

.full-w {
  max-width: 100%;
}

.home-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#how-to-play {
  margin: 20px auto;
  padding: 4rem 8rem;
  width: 768px;
  height: 720px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  // background-image: url(../assets/images/office-bg.png);
  background-color: $primary-pink;

  button {
    margin-top: 4rem;
    display: block;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: .6rem;
  }

  &.cutscene-start {
    background-image: url(../assets/images/cutscene-bg.png);
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 4rem;
      color: white;
      opacity: 0;
      animation: fadeIn 0.5s 1s forwards;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  }
}

#home {
  margin: 20px auto;
  padding: 4rem 0;
  width: 768px;
  height: 720px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(../assets/images/office-2.gif);
  background-color: $primary-pink;

  .logo {
    width: 65%;
    font-size: 2rem;
    margin-bottom: 2rem;

    h2 {
      &:last-child {
        text-align: right;
      }
    }

    .divider {
      height: 4px;
      background-color: black;
      width: 100%;
      margin: 0.5rem 0;
    }
  }

  .credits {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#game-over {
  margin: 20px auto;
  padding: 2rem 0;
  width: 768px;
  height: 720px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  // background-image: url(../assets/images/office-bg.png);
  background-color: $primary-pink;

  h2 {
    margin-bottom: .5rem;
  }

  h3 {
    margin-bottom: .5rem;
  }

  & > div {
    width: 80%;
    text-align: left;
  }

  label {
    text-align: left;
    font-size: 2rem;

  }

  input {
    width: 100%;
    padding: .75rem 1rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border: 4px solid black;
  }

  .buttons-game-over {
    margin-top: .8rem;
    margin-bottom: 1rem;
    text-align: center;
    button {
      display: inline-block;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    width: 75%;
  }

  .back-to-home {
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
  }
  

  .game-over-heading {
    font-size: 6rem;
    margin-bottom: .5rem;
    text-align: center;

    .divider {
      height: 4px;
      background-color: black;
      width: 100%;
      margin: 0.5rem 0;
    }
  }

  .credits {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#canvas-container {
  margin: 20px auto;
  cursor: none;
  width: 768px;
  height: 720px;
  position: relative;
  // background-image: url(../assets/images/bg-duckhunt.png);
  background-color: $primary-pink;
  box-shadow: 12px 12px 24px rgba($color: #000000, $alpha: 0.5);
}

#canvas-container #round-counter {
  pointer-events: none;
  position: absolute;
  bottom: 115px;
  left: 65px;
  background-color: black;
  padding-left: 4px;
  font-size: 70px;
  line-height: 30px;
  display: inline-block;
  color: #9be3be;
}
#canvas-container #hit-counter {
  pointer-events: none;
  position: absolute;
  bottom: 42px;
  left: 195px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
#canvas-container #hit-counter h2 {
  font-size: 60px;
  color: #9be3be;
}
#canvas-container #hit-counter ul {
  padding-top: 2px;
  list-style: none;
  display: flex;
  gap: 3px;
}
#canvas-container #hit-counter #hit-counter__enemies {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
#canvas-container #hit-counter #hit-counter__pass-limit {
  color: white;
  font-size: 25px;
  transform: translateY(-5px);
}
#canvas-container #bullets-container {
  pointer-events: none;
  position: absolute;
  bottom: 50px;
  left: 76px;
}
#canvas-container #bullets-container #bullets {
  list-style: none;
  display: flex;
  gap: 12px;
}
#canvas-container #bullets-container #bullets li img {
  width: 12px;
  height: 21px;
}
#canvas-container #bullets-container h2 {
  color: #f7c1b8;
  font-size: 38px;
  line-height: 20px;
}
#canvas-container #score-counter {
  pointer-events: none;
  position: absolute;
  bottom: 47px;
  right: 45px;
  color: white;
  text-align: right;
}
#canvas-container #score-counter h2 {
  font-size: 60px;
  line-height: 28px;
}

#canvas-container #between-rounds-scoreboard {
  font-size: 40px;
  line-height: 28px;
  text-align: center;
  position: absolute;
  padding: 20px;
  background-color: black;
  border-radius: 3px;
  border: 3px solid white;
  color: white;
  width: 600px;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  .between-rounds-scoreboard__score-item {
    padding-bottom: 10px;
  }

  .between-rounds-scoreboard__next-round {
    animation: blink 1s infinite;
    padding-top: 20px;
    text-align: center;
  }
}

#no-bullets-left {
  font-size: 40px;
  line-height: 28px;

  position: absolute;
  padding: 20px;
  background-color: black;
  border-radius: 3px;
  border: 3px solid white;
  color: white;
  width: fit-content;
  top: 250px;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.page-settings {
  color: white;
  font-size: 25px;
}

.cutscene-game-over {
  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  h4 {
    font-size: 2rem;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
